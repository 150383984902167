import React, { FC } from "react";
import { CircleBG } from "./circle";
import { BackdropBG } from "./backdrop";
import { LongSpiralBG } from "./long-spiral";
import { NotFoundBG } from "./not-found";
import { InternalServerErrorBG } from "./internal-server-error";
import { ModalBackdropBG } from "./modal-backdrop";
import { RightAngleArrowBackdrop } from "./right-angle-arrow-backdrop";
import { ConfettiBackdrop } from "./confetti-backdrop";
import { SwirlBackdrop } from "./swirl";
import { LeftAngleArrowBackdrop } from "./left-angle-arrow-backdrop";
import { CircleTopBG } from "./circle-top";
import { usePartnerStore } from "store/partner";
import { CheckinBackdrop } from "./checkin-backdrop";

export const Background: FC<BackgroundProps> = React.memo(
  ({
    type,
    color = "blue",
    top,
    left,
    right,
    bottom,
    height,
    pos,
    mobile = false,
  }) => {
    const { enable_visual_effects } = usePartnerStore();
    // remove all effects based on partner settings
    if (
      !enable_visual_effects &&
      ![
        ActionIconTypes.NOT_FOUND,
        ActionIconTypes.INTERNAL_SERVER_ERROR,
      ].includes(type as ActionIconTypes)
    ) {
      return null;
    }

    switch (type) {
      case ActionIconTypes.CIRCLE:
        return (
          <CircleBG
            color={color}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
          />
        );
      case ActionIconTypes.CIRCLE_TOP:
        return (
          <CircleTopBG
            color={color}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
          />
        );
      case ActionIconTypes.LONG_SPIRAL:
        return (
          <LongSpiralBG
            color={color}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            height={height}
            pos={pos}
          />
        );
      case ActionIconTypes.NOT_FOUND:
        return (
          <NotFoundBG
            color={color}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            mobile={mobile}
            transition={enable_visual_effects}
          />
        );
      case ActionIconTypes.INTERNAL_SERVER_ERROR:
        return (
          <InternalServerErrorBG
            color={color}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            mobile={mobile}
            transition={enable_visual_effects}
          />
        );
      case ActionIconTypes.CONFETTI:
        return <ConfettiBackdrop color={color} />;
      case ActionIconTypes.ARROW_HALF:
        return (
          <RightAngleArrowBackdrop color={color} height={height} left={left} />
        );
      case ActionIconTypes.ARROW_HALF_LEFT:
        return (
          <LeftAngleArrowBackdrop
            color={color}
            height={height}
            left={left}
            right={right}
          />
        );
      case ActionIconTypes.BACKDROP:
        return <BackdropBG color={color} />;
      case ActionIconTypes.MODAL_BACKDROP:
        return <ModalBackdropBG color={color} />;
      case ActionIconTypes.SWIRL:
        return <SwirlBackdrop color={color} right={right} height={height} />;
      case ActionIconTypes.CHECKIN_BACKDROP:
        return (
          <CheckinBackdrop
            color={color}
            right={right}
            height={height}
            left={left}
          />
        );
      default:
        return null;
    }
  }
);

export enum ActionIconTypes {
  ARROW = "arrow",
  ARROW_ROUNDED = "arrow_rounded",
  ARROW_HALF = "arrow_half",
  ARROW_HALF_LEFT = "arrow_half_left",
  CIRCLE = "circle",
  CIRCLE_TOP = "circle_top",
  CHECK_MARK = "check_mark",
  CONFETTI = "confetti",
  BACKDROP = "backdrop",
  LONG_SPIRAL = "long_spiral",
  NOT_FOUND = "not_found",
  INTERNAL_SERVER_ERROR = "internal_server_error",
  MODAL_BACKDROP = "modal_backdrop",
  SWIRL = "swirl",
  CHECKIN_BACKDROP = "checkin_backdrop",
}

export type BGTypes = `${ActionIconTypes}`;

export type BackgroundProps = {
  type?: BGTypes;
  color?: string;
  left?: string | number | (string | number)[];
  right?: string | number | (string | number)[];
  top?: string | number | (string | number)[];
  bottom?: string | number | (string | number)[];
  height?: string | number;
  mobile?: boolean;
  transition?: boolean;
  pos?: "absolute" | "fixed";
};
