import { createIcon } from "@chakra-ui/icons";

export const AovCheckIcon = ({ fillColor }: { fillColor?: string }) =>
  createIcon({
    displayName: "CheckIcon",
    viewBox: "0 0 24 24",
    path: (
      <>
        <mask
          id="mask0_4305_6530"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="24"
        >
          <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4305_6530)">
          <path
            d="M9.6965 17.4537L10.05 17.8073L10.4036 17.4537L20.0536 7.80375L20.4072 7.4502L20.0536 7.09664L19.5536 6.59664L19.2 6.24309L18.8465 6.59664L10.05 15.3931L6.1536 11.4966L5.80005 11.1431L5.4465 11.4966L4.9465 11.9966L4.59294 12.3502L4.9465 12.7037L9.6965 17.4537Z"
            fill={fillColor ?? "#B4FB9C"}
            stroke={fillColor ?? "#B4FB9C"}
          />
        </g>
      </>
    ),
  });
