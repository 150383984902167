import { Integration } from "@sentry/types";
import {
  init,
  replayIntegration,
  browserTracingIntegration,
} from "@sentry/react";
import { getEnvironment } from "@utils/getEnvironment";

export const SENTRY_PROD_ENDPOINT =
  "https://549d515eeff94d9ebcb90fbf00f5c31a@o571431.ingest.us.sentry.io/4505123901014016";

export const SENTRY_TEST_ENDPOINT =
  "https://83c38d59a0ea10f99e8f39d295aaa426@o4507865619759104.ingest.de.sentry.io/4507893768192080";

/**
 * In the Regex I used the flags mi standing for:
  'm': multiline mode
  'i': case insensitive
 */
const serverErrorsRegex = new RegExp("Ignore Error", "mi");
const sqzlTransmitErrorRegex = new RegExp("sqzlTransmit", "mi");
const axiosNetworkErrorRegex = new RegExp("Network Error", "mi");

export const configureSentry = () => {
  const environment = getEnvironment();
  if (environment === "development") return;

  const integrations:
    | Integration[]
    | ((integrations: Integration[]) => Integration[])
    | undefined = [browserTracingIntegration()];
  // added integration if on production environment
  if (environment === "production") {
    integrations.push(
      replayIntegration({
        mask: [".sentry-mask"],
        blockAllMedia: true,
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [/https?:\/\/((.*)\.)?alicia\.insure/],
        networkRequestHeaders: ["Cache-Control", "X-Custom-Header"],
        networkResponseHeaders: ["Referrer-Policy", "X-Custom-Header"],
      })
    );
  }

  init({
    dsn:
      environment === "production"
        ? SENTRY_PROD_ENDPOINT
        : SENTRY_TEST_ENDPOINT,
    debug: ["stage", "qa"].includes(environment),
    environment: environment,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      serverErrorsRegex,
      sqzlTransmitErrorRegex,
      axiosNetworkErrorRegex,
    ],
    allowUrls: [
      /https?:\/\/((.*)\.)?alicia\.insure/,
      /https?:\/\/((.*)\.)?aliciatech\.nl/,
    ],
    denyUrls: [/www\.knab\.nl/i],
    integrations,
    enableTracing: true,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    beforeSend(event, hint) {
      const error = hint?.originalException || hint?.syntheticException;
      if (error instanceof Error) {
        const status = (error as any).response?.status;
        if (status === 401) {
          // If the error is an unauthorized (401) response, return null to prevent sending the event to Sentry
          return null;
        }
      }
      return event;
    },
  });
};
