import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Header, HeaderRoutes, StepsProgressBar } from "alicia-design-system";
import React, { useEffect, type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { rem } from "polished";
import { useLocation, useNavigate } from "react-router-dom";
import { media } from "@utils/media";
import { useLanguageTranslation } from "hooks/useLanguageTranslation";
import Cart from "features/shared/cart";
import { usePartnerStore } from "store/partner";
import { useStepStore } from "store/steps";
import { StepTypeEnum, SupportActionEnum } from "src/types";
import { useProductsStore } from "store/products";
import {
  HeaderStepRouteEnum,
  GLOBAL_COLOR_FIELDS,
  NAVIGATION_PANE_COLOR_FIELDS,
} from "src/constants";
import { useTrengo } from "hooks/useTrengo";
import Cookies from "universal-cookie";
import {
  isInsuranceCalculatorRoute,
  isInsuranceCheckInRoute,
} from "@utils/getFlowPaths";
import { useColors } from "hooks/useColors";
import { useAuthStore } from "store/authentication";
import Editor from "features/editor";
import { navigateTo } from "services/navigateTo";
import NavigationPane from "../navigation-pane";
import BannerTicker from "../BannerTicker";

// initial pages routes list
const INSURANCE_ROUTES = [
  "/profession",
  "/products/available-products",
  "/insurance-calculator/profession",
  "/insurance-calculator/available-products",
];

// initialize cookies
const cookies = new Cookies(null, {
  path: "/",
});

const PrivateLayout: FC<PrivateLayoutProps> = ({
  children,
  showCart = false,
  showHeaderNav = false,
  showSteps = true,
  showLeftNav = false,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { getColor } = useColors();
  const navigation = useNavigate();
  const host: string = window.location.host;
  const [partner] = host.split(".");
  const { admin, clearAdminAuth, user } = useAuthStore();
  const {
    brand,
    support_email,
    support_phone_number,
    enable_visual_effects,
    name,
  } = usePartnerStore();
  const { selectedLanguage, languages, changeSelectedLanguage } =
    useLanguageTranslation();
  const { steps, setSteps, setCurrentStepId, currentId, isPageLoading } =
    useStepStore();
  const { isPastPoliciesAvailable } = useProductsStore();
  const insuranceCalculatorRoute = isInsuranceCalculatorRoute();
  const insuranceCheckInRoute = isInsuranceCheckInRoute();
  const { openTrengo } = useTrengo();

  const [isInCheckoutFlow, setIsInCheckoutFlow] = useState(false);
  const [headerNavList, setHeaderNavList] = useState<HeaderRoutes>();

  const getUserMenuSelectedOption = (): string | undefined => {
    if (
      location.pathname.includes("user/policies") ||
      location.pathname.includes("policy")
    ) {
      return "user-policies";
    } else if (location.pathname.includes("user/details")) {
      return "user-details";
    } else if (location.pathname.includes("products/available-products")) {
      return "available-insurances";
    } else {
      return undefined;
    }
  };

  const getHeaderMenuSelectedOption = (): string | undefined => {
    if (
      location.pathname.includes("user/policies") ||
      location.pathname.includes("policy")
    ) {
      return "/user/policies";
    } else {
      return location.pathname;
    }
  };

  useEffect(() => {
    const setupHeaderNavList = () => {
      const tempNavList: HeaderRoutes = {
        navList: [
          {
            id: "/products/available-products",
            title: t("header.routes.availableInsurances"),
            route: "/products/available-products",
            isMobileOnly: true,
          },
          {
            id: "/user/policies",
            title: t("header.routes.myInsurances"),
            route: "/user/policies",
            isMobileOnly: true,
          },
          {
            id: "/user/details",
            title: t("header.routes.myDetails"),
            route: "/user/details",
            isMobileOnly: true,
          },
        ],
        fontSize: rem(18),
        fontWeight: 500,
        textColor: getColor("text"),
      };

      isPastPoliciesAvailable && setHeaderNavList(tempNavList);
    };
    setupHeaderNavList();
  }, [name, isPastPoliciesAvailable, selectedLanguage?.value]);

  useEffect(() => {
    if (!isInCheckoutFlow) {
      setSteps(
        insuranceCalculatorRoute
          ? StepTypeEnum.CALCULATOR
          : StepTypeEnum.DEFAULT,
        t
      );
    }
  }, [selectedLanguage?.value, isInCheckoutFlow]);

  useEffect(() => {
    if (INSURANCE_ROUTES.includes(location.pathname)) {
      // include profession page available insurance page and the your insurances screens
      setCurrentStepId(HeaderStepRouteEnum.INSURANCE);
    }
    setIsInCheckoutFlow(location.pathname.includes("checkout"));
  }, [location]);

  const getLogoClickPath = () => {
    if (insuranceCalculatorRoute) {
      return "/insurance-calculator";
    } else if (insuranceCheckInRoute) {
      return "/insurance-checkin-welcome";
    } else {
      return "/welcome";
    }
  };

  return (
    <Flex
      flexDirection="column"
      h="100vh"
      w="100%"
      minHeight="calc(100vh)"
      background="#ffffff"
    >
      {admin && admin.name === name && (
        <Editor
          isGlobal
          isAdmin={admin && admin.name === name}
          triggerColor="#ffffff"
          position={{ top: 0, left: 0 }}
          colorsFields={GLOBAL_COLOR_FIELDS}
          component={
            <Flex
              height={16}
              bg="#C26D68"
              color="#ffffff"
              alignItems="center"
              justifyContent="center"
              gap={4}
              py={4}
              width="100%"
            >
              <Text
                fontSize={rem(16)}
                lineHeight={rem(22)}
                fontWeight="bold"
                fontFamily={brand?.font_primary}
              >
                {t("adminEditor.header.title")}
              </Text>
              <Button variant="primary" onClick={() => clearAdminAuth()}>
                {t("adminEditor.header.CTA")}
              </Button>
            </Flex>
          }
        />
      )}
      <BannerTicker />
      <Flex w="100%" zIndex="4" flexDirection="column" mb={rem(-8)}>
        <Header
          enableVisualEffects={enable_visual_effects}
          hideMobileMenu={
            t("deeplink.dismiss") !== "deeplink.dismiss"
              ? false
              : !headerNavList?.navList.length
          }
          showLanguageSelector={!isInCheckoutFlow && languages.length > 1}
          headerMenuColors={{
            mobileMenuBackgroundColor: getColor("support-menu-background"),
            mobileMenuTextColor: getColor("support-menu-text"),
            menuBackgroundColor: getColor("support-menu-background"),
            menuTextColor: getColor("support-menu-text"),
            itemColor: getColor("support-menu-background"),
            itemHoverColor: getColor("support-menu-hover"),
            itemTextColor: getColor("support-menu-text"),
            itemHoverTextColor: getColor("support-menu-text-hover"),
          }}
          languageMenuitemColorHover={getColor("support-menu-text-hover")}
          textColor={getColor("text") ?? "text"}
          imageSrc={brand?.logo_primary ?? ""}
          imageFallbackSrc={brand?.logo_secondary ?? ""}
          showSupport
          onLogoClick={() => navigation(getLogoClickPath())}
          languageMenu={{
            languages: languages,
            language: selectedLanguage,
          }}
          headerActiveRoute={getHeaderMenuSelectedOption()}
          setLanguage={(e) => {
            changeSelectedLanguage(e.value);
          }}
          showHeaderNavigation={showHeaderNav}
          headerRoutes={
            headerNavList?.navList && headerNavList?.navList.length > 0
              ? headerNavList
              : undefined
          }
          headerNavClick={(e) => navigation(e)}
          steps={[]}
          showSteps={false}
          showProgressBar={false}
          showCurrentStep
          containerZIndex={3}
          supportMenu={{
            supportMenuTitle: t("support.title") as string,
            supportMenuOptions: [
              {
                id: SupportActionEnum.CALL,
                title: support_phone_number ?? t("support.call.title"),
                description: t("support.call.description") as string,
                titlePrefix: t("support.call.titlePrefix") as string,
              },
              {
                id: SupportActionEnum.MAIL,
                title: support_email ?? t("support.mail.title"),
                description: t("support.mail.description") as string,
                titlePrefix: t("support.mail.titlePrefix") as string,
              },
              {
                id: SupportActionEnum.CHAT,
                title: t("support.chat.title") as string,
                description: t("support.chat.description") as string,
                titlePrefix: t("support.chat.titlePrefix") as string,
              },
              {
                id: SupportActionEnum.FAQ,
                title: t("support.faq.title") as string,
                description: t("support.faq.description") as string,
                titlePrefix: t("support.faq.titlePrefix") as string,
              },
            ],
            backgroundColor: getColor("support-menu-background"),
            itemColor: getColor("support-menu-background"),
            itemHoverColor: getColor("support-menu-hover"),
            itemTextColor: getColor("support-menu-text"),
            itemHoverTextColor: getColor("support-menu-text-hover"),
            onSupportMenuSelect: (e) => {
              switch (e) {
                case SupportActionEnum.CALL:
                  window.location.href =
                    "tel://" +
                    (support_phone_number ?? t("support.call.title")).replace(
                      /\s/g,
                      ""
                    );
                  break;
                case SupportActionEnum.MAIL:
                  window.location.href =
                    "mailto:" +
                    (support_email ?? t("support.mail.title")).replace(
                      /\s/g,
                      ""
                    );
                  break;
                case SupportActionEnum.CHAT:
                  openTrengo("chat");
                  break;
                case SupportActionEnum.FAQ:
                  openTrengo("help_center");
                  break;
                default:
                  break;
              }
            },
          }}
          backgroundColor="transparent"
          showUser={
            isPastPoliciesAvailable &&
            Boolean(user?.first_name && user?.last_name)
          }
          mobileBack={
            t("deeplink.dismiss") !== "deeplink.dismiss"
              ? {
                  title: t("header.routes.backToApp"),
                  onClick: () =>
                    cookies.get("platform") !== "web"
                      ? navigateTo(t("deeplink.dismiss"))
                      : navigateTo(t("header.routes.backToWeb")),
                  textColor: getColor("support-menu-text"),
                }
              : undefined
          }
          userMenu={{
            userMenuTitle: user?.first_name,
            userMenuOptions: [
              {
                label: t("header.routes.availableInsurances"),
                value: "available-insurances",
              },
              {
                label: t("header.routes.myInsurances"),
                value: "user-policies",
              },
              {
                label: t("header.routes.myDetails"),
                value: "user-details",
              },
              ...[
                partner === "knab"
                  ? {
                      label: t("header.routes.backToLabel"),
                      value: "https://www.knab.nl",
                    }
                  : undefined,
              ],
            ],
            backgroundColor: getColor("support-menu-background"),
            itemColor: getColor("support-menu-background"),
            itemHoverColor: getColor("support-menu-hover"),
            itemTextColor: getColor("support-menu-text"),
            itemHoverTextColor: getColor("support-menu-text-hover"),
            onUserMenuSelect: (e) => {
              switch (e) {
                case "available-insurances":
                  navigation("/products/available-products");
                  break;
                case "user-policies":
                  navigation("/user/policies");
                  break;
                case "user-details":
                  navigation("/user/details");
                  break;
                case "https://www.knab.nl":
                  window.location.href = "https://www.knab.nl";
                  break;
                default:
                  break;
              }
            },
            selectedOption: getUserMenuSelectedOption(),
          }}
        />
      </Flex>

      <Flex
        justifyContent="space-between"
        w="100%"
        height="100%"
        overflow="hidden"
        sx={{
          flexDirection: "row",
          [media.desktop]: {
            flexDirection: "column-reverse",
            height: "fit-content",
          },
        }}
      >
        <Flex w="100%" flexDirection="column" height="100%" overflow="hidden">
          {!isPageLoading && showSteps && (
            <Flex zIndex={3}>
              <StepsProgressBar
                items={steps}
                currentItem={currentId}
                itemColor={getColor("steps-text", "text") ?? ""}
                highlightColor={
                  getColor("steps-label-background", "accent-positive-1") ?? ""
                }
                isAnimated
                enableVisualEffects={enable_visual_effects}
              />
            </Flex>
          )}
          <Flex
            sx={{
              overflow: "hidden",
              mt: showLeftNav ? rem(8) : undefined,
              borderTop: showSteps
                ? "none"
                : `${rem(1)} solid rgba(0, 0, 0, 0.2)`,
              [media.desktop]: {
                borderTop: "none",
              },
            }}
            h="100%"
            w="100%"
          >
            {showLeftNav && (
              <Flex
                h="100vh"
                w={rem(259)}
                px={rem(14)}
                borderRight={`${rem(1)} solid rgba(0, 0, 0, 0.2)`}
                sx={{
                  display: "flex",
                  [media.desktop]: {
                    display: "none",
                  },
                }}
              >
                <Editor
                  isAdmin={admin && admin.name === name}
                  colorsFields={NAVIGATION_PANE_COLOR_FIELDS}
                  component={<NavigationPane />}
                  position={{
                    top: -3,
                    left: -2,
                  }}
                  width="100%"
                />
              </Flex>
            )}
            <Box
              width="100%"
              marginTop={rem(32)}
              height="100%"
              position="relative"
              sx={{
                overflowY: "auto",
                px: showCart === true ? rem(24) : rem(65),
                [media.desktop]: {
                  px: rem(14),
                },
              }}
            >
              {children}
            </Box>
          </Flex>
        </Flex>
        {showCart && (
          <Flex position="relative" mt={rem(8)}>
            <Cart isFunnelStarted={isInCheckoutFlow} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default PrivateLayout;

interface PrivateLayoutProps {
  children?: React.ReactNode;
  showCart?: boolean;
  showHeaderNav?: boolean;
  showSteps?: boolean;
  showLeftNav?: boolean;
}
